import { getAutofitOrigins, getCoordsBounds, getElementBounds, getSuitableBounding, getSuitableOrigins, getSuitableSize, parseOrigins } from "./select.utils";

export function _getSuitableOrigins({ coords, positionAlign, defaultOrigins, activator, popup }: any) {
    const activatorBounds = coords
        ? getCoordsBounds(coords)
        : getElementBounds(activator);

    // calc suitable origins
    const calcOrigins = getSuitableOrigins({
        activatorBounds,
        popupBounds: getElementBounds(popup),
        positionAlign,
    });
    const defaultOriginsModel = parseOrigins(defaultOrigins);
    const suitableOrigins = Object.assign(calcOrigins, defaultOriginsModel);

    return suitableOrigins;
}

export function _getSuitableSize({ coords, positionAlign, activator, popup, origins }: any) {
    const activatorBounds = coords
        ? getCoordsBounds(coords)
        : getElementBounds(activator);

    // fit size
    const suitableSize = getSuitableSize({
        activatorBounds,
        popupBounds: getElementBounds(popup),
        positionAlign: positionAlign,
        origins,
    });

    return suitableSize;
}

export function _getSuitableLocation({ coords, positionAlign, activator, popup, origins }: any) {
    const activatorBounds = coords
        ? getCoordsBounds(coords)
        : getElementBounds(activator);

    const popupBounds = getElementBounds(popup);

    // calc suitable bounds
    const suitableBounding = getSuitableBounding({
        activatorBounds,
        popupBounds,
        positionAlign,
        origins,
    });

    return suitableBounding;
}

export const delayMs = (ms: number) => new Promise((resolve) => setTimeout(() => resolve(1), ms));